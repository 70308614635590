import React from 'react';
import { getUserInfo } from './ProductService'

export const CurrentUserContext = React.createContext();

export const CurrentUserProvider = ({ children }) => {

    const [userName, setUserName] = React.useState();
    const [isAuth, setIsAuth] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState(false);

    const fetchCurrentUser = () => {

        getUserInfo()
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw res.status;
            })
            .then((data) => {
                setUserName(data.first_name);;
                setIsAuth(true);
                setIsAdmin(data.is_admin);
                setUserEmail(data.email)

            }).catch((error) => {
                setIsAuth(false);
            });


    }

    return (
        <CurrentUserContext.Provider value={{ userName, fetchCurrentUser, isAuth, setIsAuth, isAdmin, userEmail, setUserName, setUserEmail, setIsAdmin }}>
            {children}
        </CurrentUserContext.Provider>
    )
}
export const useCurrentUser = () => React.useContext(CurrentUserContext)