import React from "react"
import { useFormik, FormikProvider } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useCurrentUser } from '../service/CurrentUserContext';
import { logIn } from '../service/ProductService'
import '../style/logIn.css'
// import { CookiesProvider, useCookies } from 'react-cookie'

const LogInComponent = (props) => {
    const [loginError, setLoginError] = React.useState();
    const { setIsAuth } = useCurrentUser()
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        enableReinitialize: true,
        validateOnChange: false,
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = 'Adres email jest wymagany.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Nieprawidłowy addres email.';
            }
            if (!data.password) {
                errors.password = 'Hasło jest wymagane.';
            }
            return errors
        },
        onSubmit: values => {
            logIn(values.email, values.password)
                .then((res) => {
                    if (res.status === 200) {
                        setLoginError();
                        setIsAuth(true);
                    }
                    else if (res.status === 401) {
                        setLoginError("Podane dane są niepoprawne.");
                        formik.resetForm();
                    }
                    else {
                        setLoginError("Wystąpił problem z logowaniem. Prosimy spróbować później");
                        formik.resetForm();
                    }
                }).catch(() => {
                    setLoginError("Wystąpił problem z logowaniem. Prosimy spróbować później");
                    formik.resetForm();
                });
        }

    });

    return (
        <div className="log_in">
            {loginError ? <div className="alert">{loginError}</div> : null}
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-column gap-2 form-field">
                        <label htmlFor="email">Email</label>
                        <InputText
                            id="email"
                            name="email"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.errors.email ? <div>{formik.errors.email}</div> : null}
                    </div>
                    <div className="flex flex-column gap-2 form-field">
                        <label htmlFor="password">Hasło</label>
                        <InputText
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        {formik.errors.password ? <div>{formik.errors.password}</div> : null}
                    </div>
                    <div className="flex flex-column gap-2 form-field">
                        <Button label="Zaloguj" type="sumbmit" severity="secondary" />
                    </div>
                </form >
            </FormikProvider>
        </div>
    )
};

export default LogInComponent;
