import React from "react"
import ImageEdit from "./imageEditComponent";
import '../style/ImageEdit.css'

const ProductPhotosEdit = (props) => {
    const editImages = props.images.map((image) => <ImageEdit image={image} key={image.id} removeImage={props.removeImage} />)
    return (
        <div className="product_current_images">
            <h1>Obecne zdęcia</h1>
            {editImages}
        </div>
    )
};

export default ProductPhotosEdit;

