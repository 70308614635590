import React, { useState, useRef } from "react";
import "../style/ImageSlider.css";

const ImageSlider = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderOtherChange = useRef(0);
    const sliderRef = useRef(null);

    // Zmienne do obsługi gestów
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);


    // Obsługa kliknięcia przycisków
    const handlePrev = () => {
        if (!sliderOtherChange.current) {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? props.images.length - 1 : prevIndex - 1
            );
        }
    };

    const handleNext = () => {
        if (!sliderOtherChange.current) {
            setCurrentIndex((prevIndex) =>
                prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
            );

        }
    };


    const handleSwipe = () => {
        const distance = touchStartX.current - touchEndX.current;
        if (distance > 50) {
            // Swipe w lewo
            handleNext();
        } else if (distance < -50) {
            // Swipe w prawo
            handlePrev();
        }
    };

    return (
        <div className="image-slider-container">
            <div className="image-slider"
                ref={sliderRef}
                onTouchStart={(e) => (touchStartX.current = e.touches[0].clientX)}
                onTouchMove={(e) => (touchEndX.current = e.touches[0].clientX)}
                onTouchEnd={handleSwipe}>
                {props.images.map((image, index) => (
                    <div
                        key={index}
                        className={`image-slider-item ${index === currentIndex ? "active" : ""
                            }`}
                        style={{
                            transform: `translateX(${(index - currentIndex) * 100}%)`,
                        }}
                    >
                        <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </div>


            <button className="image-slider-button prev" onClick={handlePrev}>
                ◀
            </button>


            <button className="image-slider-button next" onClick={handleNext}>
                ▶
            </button>


            <div className="image-slider-dots">
                {props.images.map((_, index) => (
                    <span
                        key={index}
                        className={`image-slider-dot ${index === currentIndex ? "active" : ""}`}
                        onClick={() => setCurrentIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
