import React from "react"

const ErrorPage = (props) => {
    return (
        <div>
            Strona nie działa. Przepraszamy.
        </div>
    )
};

export default ErrorPage;
