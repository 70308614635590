import React, { useState, useEffect } from "react";
import '../style/CookieBanner.css'

const CookieBanner = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        // Sprawdzamy, czy użytkownik wcześniej zaakceptował cookies
        const acceptedCookies = localStorage.getItem("cookiesAccepted");
        if (!acceptedCookies) {
            setIsVisible(true);
        }
    }, []);
    const handleAccept = () => {
        // Zapisujemy decyzję w localStorage
        localStorage.setItem("cookiesAccepted", "true");
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="cookie-banner">
                <p>
                    Ta strona korzysta z plików cookies, które są niezbędne do jej prawidłowego działania.
                    Pliki te nie przechowują danych użytkownika i nie są wykorzystywane do celów marketingowych.
                    Kontynuując korzystanie ze strony, zgadzasz się na ich użycie.{" "}
                    {/* <a href="/polityka-prywatnosci">Dowiedz się więcej</a>. */}
                </p>
                <button onClick={handleAccept}>Akceptuję</button>
            </div>
        )
    );
};

export default CookieBanner;
