import React from "react"

const Projects = (props) => {
    return (
        <div>
            projekty
        </div>
    )
};

export default Projects;
