import React, { useState, useEffect, useRef } from "react";

import { useCurrentUser } from '../service/CurrentUserContext';
import { useNavigate } from 'react-router-dom'; // Importujemy hook `useNavigate`
import logo from './../logo.svg'
import { Link } from "react-router-dom";

import '../style/Header.css'



const Header = (props) => {
  const { userName } = useCurrentUser()
  const [headerHeight, setHeaderHeight] = useState(130);
  const menuRef = useRef(null); // Referencja do menu

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const navigate = useNavigate(); // Inicjalizujemy hook `useNavigate`
  const handleMenuClick = (path) => {
    setIsMenuOpen(false);
    navigate(path); // Nawigowanie do określonej ścieżki
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setHeaderHeight(70);
    } else if (window.scrollY < 30) {
      setHeaderHeight(130);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }

  };

  const openMenu = () => {
    setIsMenuOpen(true);

  };
  const closeMenu = () => {
    setIsMenuOpen(false);

  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside); // Usuń nasłuchiwacz przy odmontowaniu
    };
  }, []);



  return (
    <header className="header">
      <div className="header-container" style={{ maxHeight: headerHeight }}>
        <div className="grid">
          <div className="col-fixed logo-container" style={{ height: headerHeight }}>
            <img alt="logo" src={logo} onClick={() => handleMenuClick('/')}></img >
          </div>

          <div className="col header-content">
            <div className="grid ">
              <div className="col-4 md:col-6 lg:col-9 xl:col-9 header-content-menu">
                <nav className="navbar" ref={menuRef} onClick={(e) => e.stopPropagation()}>


                  <div className="hamburger" onClick={() => openMenu()}>
                    <>
                      <span></span>
                      <span></span>
                      <span></span>
                    </>
                  </div>
                  {/* Menu */}
                  <ul className={`menu ${isMenuOpen ? "active" : ""}`}>
                    <li>
                      <div className="close-menu" onClick={() => closeMenu()}>
                        <>
                          <span className="pi pi-times" style={{ fontSize: '1.5rem' }} />
                        </>
                      </div>
                    </li>
                    <li>
                      <div className="menu-item" onClick={() => handleMenuClick('/o-nas')}>
                        <span className="pi pi-users" style={{ fontSize: '1.5rem' }} />
                        <span className="mx-2">O nas</span>
                      </div>
                    </li>
                    <li>
                      <div className="menu-item" onClick={() => handleMenuClick('/kontakt')}>
                        <span className="pi pi-envelope" style={{ fontSize: '1.5rem' }} />
                        <span className="mx-2">Kontakt</span>
                      </div>
                    </li>
                    <li>
                      <div className="col menu-item" onClick={() => handleMenuClick('/sklep')}>
                        <span className="pi pi-shopping-bag" style={{ fontSize: '1.5rem' }} />
                        <span className="mx-2">Sklep</span>
                      </div>
                    </li>

                  </ul>

                </nav>

              </div>
              <div className="col header-content-info">
                <div className="">
                  <div className="menu-item" onClick={() => handleMenuClick('/moje-konto')}>
                    <span className="pi pi-user" style={{ fontSize: '1.5rem' }} />
                    <span className="mx-2">{userName}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </header >
  );
};
export default Header;
