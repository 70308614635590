import React from 'react';
import './../style/App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { CurrentUserProvider } from '../service/CurrentUserContext';
import Page from './Page';
import CookieBanner from "./CookieBanner";

function App() {

  return (
    <PrimeReactProvider>
      <CurrentUserProvider>
        <Router>
          <div className='app'>
            <Page />
          </div>
          <CookieBanner />
        </Router>
      </CurrentUserProvider>

    </PrimeReactProvider>
  );
}

export default App;
