import React from "react"
import { useNavigate } from 'react-router-dom';
import '../style/SimilarProductCard.css'

const SimilarProductCard = (props) => {
    let navigate = useNavigate();
    const product_url = `/sklep/produkt/${props.product.product_id}`

    function onLinkClick(e) {
        window.scrollTo(0, 0);
        e.preventDefault();
        navigate(product_url);
    }

    const mainImg = props.product.images.find((element) => element.main == true);

    return (

        <div className="similar-product-card">
            {/* Sekcja zdjęcia */}
            <div className="similar-product-image-container">
                <a onClick={onLinkClick}>
                    <img src={mainImg.image} alt={props.product.name} className="similar-product-image" />
                </a>
            </div>

            {/* Sekcja nazwy */}
            <div className="similar-product-name">
                <a onClick={onLinkClick}>
                    <div>{props.product.name}</div>
                </a>
            </div>
        </div>

    )
};

export default SimilarProductCard;
