
import React, { useState, useEffect } from 'react';
import '../style/ShopProducts.css'
import ShopProductCard from './ShopProductCard';
import { Dropdown } from 'primereact/dropdown';
import { useCurrentUser } from '../service/CurrentUserContext';
import ShopPagesNavigation from './ShopPagesNavigation';

const ShopProducts = (props) => {

    const [products, setProducts] = useState([]);
    const { isAdmin } = useCurrentUser();
    const [shopProductsLoaded, setShopProductsLoaded] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [shopResultsCount, setShopResultsCount] = useState(null);
    const [resultRange, setResultRange] = useState(null);
    const [sortOption, setSortOption] = useState({ name: '-----', code: '-main_page_priority,-popularity,-id' });
    const pageSize = 12;

    const [totalPages, setTotalPages] = useState(null);


    useEffect(() => {
        const API_URL = process.env.REACT_APP_API_URL;
        let paginationParams = `page_size=${pageSize}`;
        let link = `${API_URL}/api/product-list/`;
        let filtersString = null;
        if (props.currentPage !== 1) {
            paginationParams = paginationParams + `&page=${props.currentPage}`;
        }
        if (props.subCategory) {
            filtersString = `?sub_category=${props.subCategory.id}`
        }
        else if (props.mainCategory) {
            filtersString = `?main_category=${props.mainCategory.id}`
        }

        if (filtersString) {
            link = `${link}${filtersString}&${paginationParams}`
        }
        else {
            link = `${link}?${paginationParams}`
        }
        if (sortOption) {
            link = `${link}?${paginationParams}&ordering=${sortOption.code}`
        }
        fetch(link)
            .then((res) => {
                return res.json();
            })
            .catch((error) => {
                throw new Error(error.message);
            })
            .then((data) => {
                let firstproduct = null
                let lastproduct = null
                setProducts(data.results);
                setShopResultsCount(data.count)
                if (data.count > 0) {
                    const totalPages = Math.ceil(data.count / pageSize);
                    setTotalPages(totalPages);
                    firstproduct = pageSize * props.currentPage - pageSize + 1;
                    if (data.results.length < pageSize) {
                        lastproduct = pageSize * props.currentPage - pageSize + data.results.length;
                    }
                    else {
                        lastproduct = pageSize * props.currentPage;
                    }
                    setResultRange(`${firstproduct}-${lastproduct}`)

                }
                else {
                    setResultRange('0')
                }
                setShopProductsLoaded(true)
            })
            .catch((error) => {
                setShopProductsLoaded(false)
            })

    }, [props.subCategory, props.mainCategory, props.currentPage, pageSize, sortOption]);

    const productListTemplate = (products) => {
        return <div className="grid grid-nogutter w-full ">{products.map((product) => <ShopProductCard product={product} key={product.id} isAdmin={isAdmin} />)}</div>;
    };

    const sortOptions = [
        { name: 'Cena: od najniższej', code: 'price,-id' },
        { name: 'Cena: od najwyższej', code: '-price,-id' },
        { name: 'Najnowsze', code: '-id' },
        { name: 'Najpopularniejsze', code: '-popularity,-id' },
        { name: 'Domyślne sortowanie', code: '-main_page_priority,-popularity,-id' }
    ];

    return (

        <div className="card shop-list">
            <div>
                <div className='shop-settings-top'>
                    <div className="col-12 md:col-8 lg:col-8">
                        <span>Wyświetlono {resultRange} z {shopResultsCount} dostępnych produktów</span>
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 shop-settings-top-right">
                        <Dropdown value={sortOption} onChange={(e) => setSortOption(e.value)} options={sortOptions} optionLabel="name"
                            placeholder="Sortuj" className="w-full md:w-14rem" />
                    </div>
                </div>
                <div className="grid flex flex-row ">
                    {productListTemplate(products)}
                </div>
                <div className='shop-settings-bottom'>
                    <ShopPagesNavigation
                        totalPages={totalPages}
                        currentPage={props.currentPage}
                        onPageChange={props.onPageChange}
                    />
                </div>

            </div>

        </div >
    )
};

export default ShopProducts;
