import React, { useState, useEffect } from 'react';
import Navigation from "./Navigation";
import ShopProducts from "./ShopProducts";
import '../style/Shop.css'
import ShopHeader from './ShopHeaderComponent';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllCategories } from '../service/ProductService'

const Shop = (props) => {
    let navigate = useNavigate();
    let location = useLocation();
    let { main_category_id } = useParams();
    let { sub_category_id } = useParams();

    const [activeMainCategory, setMainCategory] = useState(null);
    const [activeSubCategory, setSubCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [categories_list, setCategoriesList] = useState([]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo(0, 0); // Przewiń do góry po zmianie strony
    };


    useEffect(() => {
        getAllCategories()
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setCategoriesList(data);
                let mainCategory = null;
                let subCategory = null;
                if (main_category_id) {
                    mainCategory = data.find(ele => ele.id == main_category_id);
                    if (mainCategory) {
                        setMainCategory(mainCategory)
                        if (sub_category_id) {
                            subCategory = mainCategory.categories.find(ele => ele.id == sub_category_id);
                            if (subCategory) {
                                setSubCategory(subCategory);
                            }
                            else {
                                navigate(`/sklep/kategoria/${mainCategory.id}`)
                            }
                        }
                    }
                    else {
                        navigate('/sklep')
                    }
                }
                else {
                    let cur_path = location.pathname;
                    if (cur_path === "/sklep" || cur_path === "/") {
                        setMainCategory(null)
                        setSubCategory(null)
                        setCurrentPage(1)
                    }
                }
            });
    }, [navigate]);


    const handleSetMainCategory = (category) => {
        if (activeMainCategory && activeMainCategory == category) {
            setMainCategory(null)
            setSubCategory(null)
        }
        else {
            setMainCategory(category)
        }
        setCurrentPage(1)

    }
    const handleSetSubCategory = (category) => {
        if (!category) {
            setCurrentPage(1)
            setSubCategory(null)
            return;
        }
        const mainCategory = categories_list.find(ele => ele.id == category.main_category_id);
        if (activeSubCategory && activeSubCategory == category) {
            setSubCategory(null)
        }
        else {
            setSubCategory(category)
            setMainCategory(mainCategory)
        }
        setCurrentPage(1)

    }

    return (
        <div className="shop">
            <ShopHeader setMainCategory={setMainCategory} setSubCategory={setSubCategory} activeMainCategory={activeMainCategory} activeSubCategory={activeSubCategory} />
            <div className="shop-main">
                <Navigation setMainCategory={handleSetMainCategory} setSubCategory={handleSetSubCategory} categories_list={categories_list} activeMainCategory={activeMainCategory} activeSubCategory={activeSubCategory} />

                <ShopProducts mainCategory={activeMainCategory} subCategory={activeSubCategory} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>

        </div>
    )
};
export default Shop;

