import React, { useState, useEffect } from 'react';
import './../style/App.css';
import Header from './Header';
import MainPage from './MainPage';
import Footer from './Footer';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { useCurrentUser } from '../service/CurrentUserContext';

const Page = (props) => {
    const { fetchCurrentUser, isAuth } = useCurrentUser()

    useEffect(() => {
        fetchCurrentUser();
    }, [isAuth]);


    return (
        <>
            <Header />
            <main>
                <MainPage />
            </main>

            <Footer />

        </>
    );
}

export default Page;
