import React, { useEffect, useState } from 'react';
import ProductTile from './ProductTile'; // Importuj nowy komponent
import '../style/ProductGroupsComponent.css';
import { getProductsByProductGroup } from '../service/ProductService'; // Importuj nową funkcję

const ProductGroupsComponent = ({ groupId, activeProductId }) => {
    const [products, setProducts] = useState([]);

    const fetchProducts = async () => {
        getProductsByProductGroup(groupId)
            .then(res => res.json())
            .then(data => {
                setProducts(data.results);
            });

    };

    useEffect(() => {
        if (!groupId) {
            setProducts([]);
            return;
        }

        fetchProducts();
    }, [groupId, activeProductId]);


    return (
        products.length > 1 ? (
            <div className="product-tiles-section">
                <h2 className="product-tiles-header">Pozostałe warianty:</h2>
                <div className="product-tiles">
                    {products.map(product => (
                        <ProductTile
                            key={product.id}
                            product={product}
                            isActive={activeProductId === product.id}
                        />
                    ))}
                </div>
            </div>
        ) : null
    );
};

export default ProductGroupsComponent;