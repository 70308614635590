import React from "react"
import "../style/NoAccessPage.css";

const NoAccessPage = (props) => {
    return (
        <div className="no-access-page">
            <h1 className="no-access-title">Brak uprawnień</h1>
            <p className="no-access-message">
                Przepraszamy, ale nie masz odpowiednich uprawnień, aby uzyskać dostęp do tej strony.
            </p>
            <button
                className="no-access-button"
                onClick={() => (window.location.href = "/")}
            >
                Powrót do strony głównej
            </button>
        </div>
    )
};

export default NoAccessPage;
