import React from 'react';
import '../style/ShopMenu.css'
import CategoryCard from './CategoryComponent';

const ShopMenu = (props) => {

    const menuMainCategories = props.categories_list.map((mainCategory) => <CategoryCard activeSubCategory={props.activeSubCategory} activeMainCategory={props.activeMainCategory} setMainCategory={props.setMainCategory} setSubCategory={props.setSubCategory} mainCategory={mainCategory} key={mainCategory.id} hideSidebar={props.hideSidebar} />)

    return (
        <div>
            {menuMainCategories}
        </div >

    )
};

export default ShopMenu;
