import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import ShopMenu from './shopMenu';
import '../style/Navigation.css'

const Navigation = (props) => {
  const [visible, setVisible] = useState(false);

  const hideSidebar = () => {
    setVisible(false);
  };

  return (
    <div>
      {window.innerWidth < 1170
        ? <div className=" flex flex-column align-items-center gap-3 manu_slide_bar">
          <Sidebar visible={visible} onHide={() => setVisible(false)}>
            <ShopMenu setMainCategory={props.setMainCategory} setSubCategory={props.setSubCategory} categories_list={props.categories_list} activeMainCategory={props.activeMainCategory} activeSubCategory={props.activeSubCategory} hideSidebar={hideSidebar}></ShopMenu>
          </Sidebar>
          <Button className='fixed' onClick={() => setVisible(true)} >Kategorie</Button>
        </div >
        : <ShopMenu setMainCategory={props.setMainCategory} setSubCategory={props.setSubCategory} categories_list={props.categories_list} activeMainCategory={props.activeMainCategory} activeSubCategory={props.activeSubCategory} hideSidebar={hideSidebar}></ShopMenu>
      }

    </div >

  )
};

export default Navigation;
