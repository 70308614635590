import React from "react"
import { useCurrentUser } from '../service/CurrentUserContext';
import LogInComponent from "./LogIn";
import UserPage from "./UserPage";


const MyAccount = (props) => {
    const { isAuth } = useCurrentUser()
    return (
        <div>
            {isAuth ? <UserPage /> : <LogInComponent />}
        </div>
    )
};

export default MyAccount;
