import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ShopHeader from './ShopHeaderComponent';
import '../style/ProductPage.css'
import ImageSlider from './ImageSlider';
import { getProductDetailedInfo, incrementProductVistitInfo, getProductsByProductGroup } from '../service/ProductService'
import SimilarProductSlider from './SimilarProductSlider';
import ProductDetailsComponent from './ProductDetailsComponent';
import ProductGroupsComponent from './ProductGroupsComponent';
import ProductBuySectionComponent from './productBuySectionComponent';

const ProductPageComponent = (props) => {

    let { product_id } = useParams();
    const [product, setproduct] = useState(null);
    const [images, setImages] = useState(null);
    const [productGroup, setProductGroups] = useState(null);

    useEffect(() => {
        getProductDetailedInfo(product_id)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setproduct(data)
                setImages()
                if (data.product_group) {
                    setProductGroups(data.product_group.id)
                }
                else {
                    setProductGroups(null)
                }
                const temp_data_images = data.images
                const imagelist = temp_data_images.map((img) => img.image
                );
                setImages(imagelist);
                incrementProductVistitInfo(product_id);


            })
    }, [product_id]);


    const formatDays = (days) => {
        if (days === 1) return `do ${days} dnia roboczego`;
        return `${days} dni roboczych`;
    }

    return (
        <>{product ?
            (<>
                <div className="shop">
                    <ShopHeader activeMainCategory={product.category.main_category} activeSubCategory={product.category} product={product} />
                    <div className='product_info'>
                        <div className="grid flex flex-row">
                            <div className="col-12 md:col-12 lg:col-5 xl:col-5 galery-container">
                                <div className="galery">
                                    <ImageSlider images={images} />
                                </div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-7 xl:col-7 product-main-description">
                                <div className="product-name-price-section">
                                    <h1>{product.name}</h1>
                                    <p>
                                        <span>{product.price} zł</span>
                                    </p>
                                </div>


                                <div className="buy-section">
                                    <ProductBuySectionComponent shop_link={product.shop_link} />
                                </div>
                                <div className="similar-product-section">
                                    <ProductGroupsComponent activeProductId={product.id} groupId={productGroup} />
                                </div>
                            </div>
                        </div>
                        <div className="product-details-section">
                            <ProductDetailsComponent
                                description={product.description}
                                notes={product.note}
                                deliveryInfo={`<p><b>Zamówienia wysyłamy w terminie ${formatDays(product.waiting_time)}.</b> W przypadku większej ilości zamówień czas realizacji może ulec zmianie.</p>`}
                                parameters={product.parameters}
                            />
                        </div>
                    </div>
                </div >
                <SimilarProductSlider productGroup={product.product_group} activeProductId={product.id} mainCategoryId={product.category.main_category.id} />
            </>
            ) : (
                null
            )
        }
        </>

    )
};

export default ProductPageComponent;
