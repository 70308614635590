import React, { useState } from 'react';
import '../style/ProductDetailsComponent.css';

const ProductDetailsComponent = ({ description, notes, deliveryInfo, parameters }) => {
    const [activeTab, setActiveTab] = useState('description');

    const renderContent = () => {
        switch (activeTab) {
            case 'description':
                return <div className="product-details-content" dangerouslySetInnerHTML={{ __html: description }} />;
            case 'notes':
                return <div className="product-details-content" dangerouslySetInnerHTML={{ __html: notes }} />;
            case 'deliveryInfo':
                return <div className="product-details-content" dangerouslySetInnerHTML={{ __html: deliveryInfo }} />;
            case 'parameters':
                return <div className="product-details-content" dangerouslySetInnerHTML={{ __html: parameters }} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="product-details-tabs">
                <button className={`product-details-tab ${activeTab === 'description' ? 'active' : ''}`} onClick={() => setActiveTab('description')}>Opis produktu</button>
                <button className={`product-details-tab ${activeTab === 'parameters' ? 'active' : ''}`} onClick={() => setActiveTab('parameters')}>Parametry produktu</button>
                <button className={`product-details-tab ${activeTab === 'notes' ? 'active' : ''}`} onClick={() => setActiveTab('notes')}>Uwagi o produkcie</button>
                <button className={`product-details-tab ${activeTab === 'deliveryInfo' ? 'active' : ''}`} onClick={() => setActiveTab('deliveryInfo')}>Informacje o dostawie</button>
            </div>
            <div className="product-details-content-container">
                {renderContent()}
            </div>
        </>
    );
};

export default ProductDetailsComponent;