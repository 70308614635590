const API_URL = process.env.REACT_APP_API_URL



const refreshToken = async () => {
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        credentials: "include",

    };
    return await fetch(`${API_URL}/api/token/refresh/`, requestOptions)
}

export const logIn = async (email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ email: email, password: password })
    };
    return await fetch(`${API_URL}/api/login/`, requestOptions)
}

export const logout = async () => {
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        credentials: "include",

    };
    return await fetch(`${API_URL}/api/logout/`, requestOptions)
}

const authRequest = async (regMethod, path, body = null) => {
    const reqUrl = `${API_URL}${path}`;
    // console.log(reqUrl);
    const requestOptions = {
        method: regMethod,
        // headers: { "Content-Type": "application/json" },
        credentials: "include",
    };
    if (body && ["POST", "PUT", "PATCH"].includes(regMethod.toUpperCase())) {
        requestOptions.body = body;
    }
    // console.log("pierwsz proba");
    const request = await fetch(reqUrl, requestOptions);
    if (request.status === 401) {
        // console.log("cos nie tak jest 401");
        // console.log("refreshujemyy")
        const refresh_request = await refreshToken();
        if (refresh_request.status === 200) {
            // console.log("pyta,my ponownie")
            return await fetch(reqUrl, requestOptions)
        }
        // console.log("nie udalo sie refresh")
        return refresh_request
    }
    // console.log("udalo sie za pierwszym razem")
    return request
};

export const getAllCategories = async () => {

    return await fetch(`${API_URL}/api/all-categories-list/`);
};


export const getAllPruductGroups = async () => {

    return await fetch(`${API_URL}/api/all-product-groups-list/`);
};

export const getProductDetailedInfo = async (id) => {
    const requestOptions = {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
        credentials: "include",

    };
    return await fetch(`${API_URL}/api/product-detailed/` + id, requestOptions);
}

export const incrementProductVistitInfo = async (id) => {
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        credentials: "include",

    };
    return await fetch(`${API_URL}/api/products/${id}/visit/`, requestOptions);
}

export const increaseProductQuantity = async (id, increaseBy) => {
    const reqUrl = `/api/product-view/${id}/change-quantity/`;

    var formData = new FormData();
    formData.append("increase_by", increaseBy);
    return await authRequest('POST', reqUrl, formData);
};

export const increasePriority = async (id, increaseBy) => {
    const reqUrl = `/api/product-view/${id}/change-priority/`;

    var formData = new FormData();
    formData.append("increase_by", increaseBy);
    return await authRequest('POST', reqUrl, formData);
};

export const createNewProduct_2 = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
    };
    return await fetch(`${API_URL}/api/product-view/`, requestOptions)
}

export const createNewProduct3 = async (data) => {
    try {
        const response = await fetch(`${API_URL}/api/product-view/`, {
            method: 'POST',
            headers: {},
            body: data,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error); // Obsługa błędu
        throw error; // Opcjonalnie przekaż błąd dalej
    }
};

export const createNewProduct = async (data) => {
    const url = `/api/product-view/`;
    return await authRequest('POST', url, data);
};

export const editExistingProduct = async (id, data) => {
    const url = `/api/product-view/${id}/`;
    return await authRequest('PUT', url, data);
};

export const editExistingProduct2 = async (id, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: {},
        body: data
    };
    return await fetch(`${API_URL}/api/product-view/` + id + '/', requestOptions)
}
export const getUserInfo = async () => {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { "Content-Type": "application/json" },
    //     credentials: "include",

    // };sendAuthGuardedRequest('POST', '/api/token/refresh/')
    return await authRequest('GET', '/api/user_info/');
}

export const getProductsByProductGroup = async (product_group_id) => {
    return await fetch(`${API_URL}/api/product-list/?product_group=${product_group_id}`);
}


