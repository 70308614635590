

import React from 'react';
import '../style/ProductTile.css';
import { useNavigate } from 'react-router-dom';

const ProductTile = ({ product, isActive }) => {

    let navigate = useNavigate();
    const product_url = `/sklep/produkt/${product.product_id}`

    function onLinkClick(e) {
        e.preventDefault();
        navigate(product_url);
    }
    const getMainImage = (images) => {
        if (!Array.isArray(images)) return null; // Sprawdź, czy images jest tablicą
        const mainImage = images.find(image => image.main === true); // Znajdź pierwszy obraz z main === true
        return mainImage ? mainImage.image : null; // Zwróć URL obrazu lub null, jeśli nie znaleziono
    };
    const mainImage = getMainImage(product.images);
    return (
        <a href={product_url} onClick={onLinkClick}>
            <div
                className={`product-tile ${isActive ? 'active' : ''}`}
            >
                <img src={mainImage} alt={product.name} className="product-image" />
                <div className="overlay">

                    <div className="product-price">{`${product.price} zł`}</div>
                </div>
            </div>
        </a >
    );
};

export default ProductTile;
