import React from 'react';

const ProductBuySectionComponent = ({ shop_link }) => {
    const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL;
    const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL;

    return (
        <div className="product-buy-section">
            <h2>Serdecznie zapraszamy do złożenia zamówienia za pośrednictwem:</h2>
            <div>
                <a href={FACEBOOK_URL} target="_blank" rel="noreferrer" className="button facebook">Facebook</a>
                <a href={INSTAGRAM_URL} target="_blank" rel="noreferrer" className="button instagram">Instagram</a>
                {shop_link ? (
                    <a href={shop_link} target="_blank" rel="noreferrer" className="button allegro">Allegro</a>
                ) : null}
            </div>
        </div>
    );
};

export default ProductBuySectionComponent;
