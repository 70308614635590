import React from "react"
import '../style/Contact.css'

const Contact = (props) => {
    const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL;
    const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL;
    const ALLEGRO_URL = process.env.REACT_APP_ALLEGRO_URL;
    return (
        <div className="contact-page">
            <h1 className="contact-title">Skontaktuj się z nami!</h1>
            <p className="contact-description">
                Jesteśmy do Twojej dyspozycji! Skontaktuj się z nami, a z chęcią odpowiemy na wszystkie pytania.
                Oferujemy indywidualne podejście i szybką reakcję.
            </p>

            <div className="contact-methods">
                {/* Kontakt przez e-mail */}
                <div className="contact-item">
                    <h2 className="contact-heading">E-mail</h2>
                    <p>
                        Napisz do nas na:{" "}
                        <a href="mailto:zetkagraw@gmail.com" className="contact-link">
                            zetkagraw@gmail.com
                        </a>
                    </p>
                </div>

                {/* Kontakt przez Facebooka */}
                <div className="contact-item">
                    <h2 className="contact-heading">Facebook</h2>
                    <p>
                        Odwiedź nasz profil{" "}
                        <a
                            href={FACEBOOK_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        >
                            Facebook
                        </a>
                    </p>
                </div>

                {/* Kontakt przez Instagrama */}
                <div className="contact-item">
                    <h2 className="contact-heading">Instagram</h2>
                    <p>
                        Zobacz nasze inspiracje na{" "}
                        <a
                            href={INSTAGRAM_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        >
                            Instagram
                        </a>
                    </p>
                </div>

                {/* Konto Allegro */}
                <div className="contact-item">
                    <h2 className="contact-heading">Allegro</h2>
                    <p>
                        Sprawdź nasze oferty na{" "}
                        <a
                            href={ALLEGRO_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        >
                            Allegro
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Contact;
