import React from "react"
import '../style/UserPage.css'
import { Button } from 'primereact/button';
import { useCurrentUser } from '../service/CurrentUserContext';
import { logout } from '../service/ProductService'


const UserPage = (props) => {
    const { isAdmin, userName, userEmail, setIsAuth, setUserName, setIsAdmin, setUserEmail } = useCurrentUser()
    const refresh = () => {
        logout()
            .then((res) => {
                if (res.status === 200 || res.status === 205) {
                    setIsAuth(false);
                    setUserName(null);
                    setIsAdmin(false);
                    setUserEmail(null);
                }
                else {
                    console.log("TODO")
                    console.log(res.status)
                }
            })

    };

    return (
        <div className="user-info">
            <strong>Imię:</strong> {userName}<br></br>
            <strong>Email:</strong> {userEmail}<br></br>
            <strong>Uprawnienia Admina:</strong> {isAdmin ? "Tak" : "Nie"}<br></br><br></br>
            <Button label="Wyloguj" severity="secondary" onClick={refresh} />
        </div>
    )
};

export default UserPage;
