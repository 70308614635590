import React from "react"
import { Button } from 'primereact/button';

const ImageEdit = (props) => {
    console.log(props.image);
    // const header = props.image;
    return (
        <div className="product_current_image">
            <div className="product_current_image_image">
                <img src={props.image.image} alt="Phototo edit" />
            </div>
            <div className="product_current_image_button">
                <Button type="button" label="Usuń" severity="danger" rounded onClick={() => props.removeImage(props.image)} />
            </div>
        </div>



    )
};

export default ImageEdit;
