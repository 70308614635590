import React from 'react';
import '../style/ShopPagesNavigation.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ShopPagesNavigation = ({ totalPages, currentPage, onPageChange }) => {
    const renderPageNumbers = () => {
        let pages = [];

        if (totalPages <= 8) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`page-button ${i === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pages.push(
                <button
                    key={1}
                    className={`page-button ${1 === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(1)}
                >
                    1
                </button>
            );
            pages.push(
                <button
                    key={2}
                    className={`page-button ${2 === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(2)}
                >
                    2
                </button>
            );
            pages.push(
                <button
                    key={3}
                    className={`page-button ${3 === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(3)}
                >
                    3
                </button>
            );

            if (currentPage > 5) {
                pages.push(<span key="left-dots" className="dots">...</span>);
            }

            const startPage = Math.max(4, currentPage - 1);
            const endPage = Math.min(totalPages - 3, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`page-button ${i === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 4) {
                pages.push(<span key="right-dots" className="dots">...</span>);
            }

            pages.push(
                <button
                    key={totalPages - 2}
                    className={`page-button ${totalPages - 2 === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(totalPages - 2)}
                >
                    {totalPages - 2}
                </button>
            );
            pages.push(
                <button
                    key={totalPages - 1}
                    className={`page-button ${totalPages - 1 === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(totalPages - 1)}
                >
                    {totalPages - 1}
                </button>
            );
            pages.push(
                <button
                    key={totalPages}
                    className={`page-button ${totalPages === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(totalPages)}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    return (
        <div className="shop-pages-navigation">
            {currentPage > 1 && (
                <button className="page-button" onClick={() => onPageChange(currentPage - 1)}>
                    <FaChevronLeft /> {/* Ikona strzałki w lewo */}
                </button>
            )}
            {renderPageNumbers()}
            {currentPage < totalPages && (
                <button className="page-button" onClick={() => onPageChange(currentPage + 1)}>
                    <FaChevronRight /> {/* Ikona strzałki w prawo */}
                </button>
            )}
        </div>
    );
};

export default ShopPagesNavigation;