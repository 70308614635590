import React from "react"
import { useNavigate } from 'react-router-dom';
import '../style/ShopHeader.css'
import { useCurrentUser } from '../service/CurrentUserContext';

const ShopHeader = (props) => {
    const { isAdmin } = useCurrentUser();
    let navigate = useNavigate();
    const shopCategoryUrl = '/sklep/kategoria';
    const shopproductUrl = '/sklep/produkt';
    const editproductUrl = '/admin/edytuj_produkt';

    const handleAddProducClick = () => {
        navigate("/admin/dodaj_produkt");
    };

    const handleAddSimilarProducClick = () => {
        navigate("/admin/dodaj_produkt", { state: { similarProduct: props.product } });
    };

    const handleEditProductClick = () => {
        navigate(`${editproductUrl}/${props.product.product_id}`);
    };



    function HandleGoToHomePageClick(e) {
        e.preventDefault();
        navigate('/');
    }
    function HandleGoToSchopClick(e) {
        e.preventDefault();
        navigate('/sklep');
        if (props.setSubCategory && props.setMainCategory) {
            props.setMainCategory(null);
            props.setSubCategory(null);
        }

    }
    function HandleAllProductsClick(e) {
        e.preventDefault();
        if (props.setSubCategory && props.setMainCategory) {
            props.setMainCategory(null);
            props.setSubCategory(null);
        }
        navigate('/sklep');
    }
    function HandleMaincategoryClick(e, categoryLink) {
        e.preventDefault();
        if (props.setSubCategory) {
            props.setSubCategory(null);
        }
        navigate(categoryLink)
    }
    function HandleSubCategoryClick(e, categoryLink) {
        e.preventDefault();
        if (props.setSubCategory) {
            props.setSubCategory(props.activeSubCategory);
        }

        navigate(categoryLink)


    }
    function HandleProductClick(e, productLink) {
        e.preventDefault();
        if (props.setSubCategory) {
            props.setSubCategory(props.activeSubCategory);
        }

        navigate(productLink)


    }

    const shopHeaderStyle = {
        paddingLeft: window.innerWidth < 1170 && !props.product ? '35px' : '0'
    };

    const homePage = <a href={'/'} id="myLink" onClick={HandleGoToHomePageClick} > ZET-KA GRAW</a >;
    const shopPage = <a href={'/sklep'} id="myLink" onClick={HandleGoToSchopClick} > / Sklep</a >;
    const allProducts = <a href={'/sklep'} id="myLink" onClick={HandleAllProductsClick} > / Wszystkie Produkty</a >;
    const mainCategory = props.activeMainCategory ? <a href={`${shopCategoryUrl}/${props.activeMainCategory.id}`} id="myLink" onClick={(e) => HandleMaincategoryClick(e, `${shopCategoryUrl}/${props.activeMainCategory.id}`)} > / {props.activeMainCategory.name}</a > : null;
    const subCategory = props.activeSubCategory ? <a href={`${shopCategoryUrl}/${props.activeMainCategory.id}/${props.activeSubCategory.id}`} id="myLink" onClick={(e) => HandleSubCategoryClick(e, `${shopCategoryUrl}/${props.activeMainCategory.id}/${props.activeSubCategory.id}`)}> / {props.activeSubCategory.name}</a > : null;
    const product = props.product ? <a href={`${shopproductUrl}/${props.product.product_id}`} id="myLink" onClick={(e) => HandleProductClick(e, `${shopproductUrl}/${props.product.product_id}`)}> / {props.product.name}</a > : null;
    const editOptions = isAdmin && props.product ? (
        <>
            <button className="shopAddButton" onClick={handleEditProductClick}>
                <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>
            <button className="shopAddButton" onClick={handleAddSimilarProducClick}>
                <i className="pi pi-copy" style={{ fontSize: '1.5rem' }}></i>
            </button>
        </>
    ) : null;

    const addOption = isAdmin ? (
        <button className="shopAddButton" onClick={handleAddProducClick}>
            <i className="pi pi-plus" style={{ fontSize: '1.5rem' }}></i>
        </button>
    ) : null;

    return (
        <div className="shop_header" style={shopHeaderStyle}>
            < h4 className="shopHeaderNavigation" > {homePage} {shopPage} {allProducts} {mainCategory} {subCategory}</h4 >
            {editOptions}
            {addOption}
        </div >
    )
};

export default ShopHeader;
