import React from "react"
import { useNavigate } from 'react-router-dom';

const CategoryCard = (props) => {
    let navigate = useNavigate();
    function handleMainCategoryOnLinkClick(e, category, mainCatUrl) {
        e.preventDefault();
        navigate(mainCatUrl);
        props.setMainCategory(category)
        props.setSubCategory(null)
    }
    function handleSubCategoryOnLinkClick(e, category, mainCatUrl) {
        e.preventDefault();
        navigate(`${mainCatUrl}/${category.id}`);
        props.setSubCategory(category)
        props.hideSidebar()

    }


    const mainCatUrl = `/sklep/kategoria/${props.mainCategory.id}`
    const subCategories = props.mainCategory.categories.map((subCategory) => <li className={(subCategory == props.activeSubCategory ? 'li-selected' : '')} key={subCategory.id} ><a href={`${mainCatUrl}/${subCategory.id}`} onClick={(e) => handleSubCategoryOnLinkClick(e, subCategory, mainCatUrl)}>{subCategory.name}</a></li>);

    return (
        <div className='category-box'>
            <a href={mainCatUrl} onClick={(e) => handleMainCategoryOnLinkClick(e, props.mainCategory, mainCatUrl)}>
                <div className={"main-category " + (props.activeMainCategory == props.mainCategory ? 'main-category-box-expanded main-category-box-selected' : '')}>
                    {props.mainCategory.name}
                </div>
            </a>
            <div className={"sub-category-box " + (props.activeMainCategory != props.mainCategory ? 'sub-category-box-hidden' : '')} >
                <ul>
                    {subCategories}
                </ul>
            </div>
        </div>
    )
};

export default CategoryCard;
