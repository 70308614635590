import React from "react"
import '../style/AboutUs.css'
import aboutUs from '../assets/images/aboutUs.jpg';

const AboutUs = (props) => {
    return (
        <div>

            <div className="company-description">
                <h2 className="company-title">Z miłości do drewna</h2>
                <p className="company-paragraph">
                    Tworzymy oryginalne drewniane dekoracje, które przyciągają uwagę i nadają wnętrzom wyjątkowy charakter.
                </p>
                <p className="company-paragraph">
                    Nasza oferta obejmuje indywidualne projekty, personalizowane prezenty, kartki okolicznościowe oraz
                    gadżety reklamowe, które idealnie wpisują się w potrzeby każdego klienta.
                </p>
                <p className="company-highlight">
                    W każdy projekt wkładamy dużo serca i pasji, aby zapewnić pełną satysfakcję naszych klientów.
                </p>
                <p className="company-paragraph">
                    Zaufaj nam i odkryj piękno naturalnych, drewnianych dekoracji, które opowiadają własną historię.
                </p>
                <img src={aboutUs} alt="Nasze Portfolio" className="company-image" />
            </div>

        </div>

    );
};

export default AboutUs;
