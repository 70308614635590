
import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import ProductPhotosEdit from './ProductEditPhotos'
import { useCurrentUser } from '../service/CurrentUserContext';
import NoAccessPage from './NoAccessPage';
import JoditEditor from 'jodit-react';

import '../style/ProductCreate.css'
import { getAllCategories, getProductDetailedInfo, createNewProduct, editExistingProduct, getAllPruductGroups } from '../service/ProductService'
import React from "react"

const ProductCreateComponent = (props) => {
    const { isAdmin, fetchCurrentUser } = useCurrentUser();
    let { product_id } = useParams();
    const editorRef = useRef(null);
    const [mainImagesTotalSize, setMainImagesTotalSize] = useState(0);
    const [curentImages, setcurentImages] = useState([]);
    const [otherImagesTotalSize, setOtherImagesTotalSize] = useState(0);
    const mainPhotoUploadRef = useRef(null);
    const otherPhotoUploadRef = useRef(null);

    const location = useLocation();
    const { similarProduct } = location.state || {};

    const [categories, setCategories] = useState(null);
    const [productGroups, setProductGroups] = useState(null);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            id: null,
            name: "",
            category: null,
            product_group: null,
            price: null,
            quantity: null,
            waiting_time: null,
            short_description: '',
            description: '',
            parameters: '',
            note: '',
            shop_link: '',
            images: [],
            main_picturer: null,
            main_images_files: [],
            images_files: []
        },
        enableReinitialize: true,
        validateOnChange: true,
        validate: (data) => {
            let errors = {};
            if (!data.name) {
                errors.name = 'Podaj nazwe';
            }
            if (!data.category) {
                errors.category = 'Wybierz kategorie.';
            }
            if (!data.shop_link) {
                errors.shop_link = 'Podaj link do sklepu.';
            }
            if (!data.price) {
                errors.price = 'Podaj cenę.';
            }
            if (!data.quantity) {
                errors.quantity = 'Podaj ilość.';
            }
            if (!data.waiting_time) {
                errors.waiting_time = 'Podaj czas oczekiwania.';
            }
            if (!data.short_description) {
                errors.short_description = 'Podaj krótki opis produktu.';
            }
            if (!data.description) {
                errors.description = 'Podaj dokładny opis produktu.';
            }
            if (!data.parameters) {
                errors.parameters = 'Podaj parametry produktu.';
            }
            if (!data.note) {
                errors.note = 'Podaj uwagi do produktu.';
            }
            if (!product_id && data.main_images_files.length < 1) {
                errors.main_images_files = 'Wybierz zdjęcie główne produktu.';
            }
            else if (data.main_images_files.length > 1) {
                errors.main_images_files = 'Wybierz tylko jedno zdjęcie główne produktu.';
            }
            return errors
        },
        onSubmit: values => {
            if (product_id) {
                editProduct(values.id, values)
            } else {
                createProduct(values)
            }

        }

    });

    const prepareProductToEdit = (data) => {
        var formData = new FormData();
        formData.append("name", data.name);
        formData.append("category", data.category.id);
        formData.append("price", data.price);
        formData.append("quantity", data.quantity);
        formData.append("waiting_time", data.waiting_time);
        formData.append("short_description", data.short_description);
        formData.append("description", data.description);
        formData.append("parameters", data.parameters);
        formData.append("shop_link", data.shop_link);
        formData.append("note", data.note);
        if (data.product_group) {
            formData.append("product_group", data.product_group.id);
        }
        for (const image of curentImages) {
            formData.append('images', image.id);
        }

        if (data.main_images_files) {
            for (const file of data.main_images_files) {
                formData.append('main_images_files', file);
            }
        }
        if (data.image_files) {
            for (const file of data.image_files) {
                formData.append('image_files', file);
            }
        }

        return formData;
    }

    const createProduct = (data) => {
        const formData = prepareProductToEdit(data);
        createNewProduct(formData)
            .then((res) => {
                if (res.status === 201) {
                    return res.json();
                }
                else if (res.status === 401) {
                    throw new Error("Podane dane są niepoprawne.");
                }
                else if (res.status === 403) {
                    throw new Error("Nie masz uprawnień aby dodać produkt.");
                }
                else {
                    throw new Error("Wystąpił problem z logowaniem. Prosimy spróbować później")
                }
            }).catch((error) => {
                throw new Error(error.message);
            })
            .then((data) => {
                navigate('/sklep/produkt/' + data.product_id)
            }).catch((error) => {
                console.log(error.message);
                alert(error.message)
            })
    };

    const editProduct = (id, data) => {
        const formData = prepareProductToEdit(data);
        editExistingProduct(id, formData)
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    throw new Error("Podane dane są niepoprawne.");
                }
                else if (res.status === 403) {
                    throw new Error("Nie masz uprawnień do edycji.");
                }
                else {
                    throw new Error("Wystąpił problem z logowaniem. Prosimy spróbować później")
                }
            }).catch((error) => {
                throw new Error(error.message);
            })
            .then((data) => {
                navigate('/sklep/produkt/' + data.product_id)
            }).catch((error) => {
                console.log(error.message);
                alert(error.message)
            })
    };

    const fetchCategorites = () => {
        getAllCategories()
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const categorieData = categoriesTemplate(data)
                setCategories(categorieData);
            })

    }

    const fetchProductGroups = () => {
        getAllPruductGroups()
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const productGroupsData = productGroupsTemplate(data)
                setProductGroups(productGroupsData);
            }).catch((error) => {
                console.error(error.message);
                alert('Wystąpił problem z pobraniem grup produktów.');
            });
    }

    const categoriesTemplate = (main_categories) => {
        var categories = [];
        for (const main_category of main_categories) {
            for (const category of main_category.categories) {
                let category_name = "".concat(main_category.name, ' > ', category.name)
                categories.push({ name: category_name, id: category.id })
            }
        }
        return (categories)
    }

    const productGroupsTemplate = (productGroups) => {
        var groups = [];
        for (const group of productGroups) {
            groups.push({ name: group.name, id: group.id })
        }
        return (groups)
    }

    useEffect(() => {
        fetchCurrentUser();
        formik.resetForm();
        fetchCategorites();
        fetchProductGroups();
        if (product_id) {
            async function setDataInFormEdit(product) {
                await formik.setFieldValue("id", product.id, true);
                await formik.setFieldValue("name", product.name, true);
                let name = product.category.main_category.name + ' > ' + product.category.name
                let tempCategory = { id: product.category.id, name: name }
                await formik.setFieldValue("category", tempCategory, true);
                await formik.setFieldValue("price", product.price, true);
                await formik.setFieldValue("quantity", product.quantity, true);
                await formik.setFieldValue("waiting_time", product.waiting_time, true);
                await formik.setFieldValue("short_description", product.short_description, true);
                await formik.setFieldValue("description", product.description, true);
                await formik.setFieldValue("parameters", product.parameters, true);
                await formik.setFieldValue("note", product.note, true);
                await setcurentImages(product.images)
                if (product.shop_link) {
                    await formik.setFieldValue("shop_link", product.shop_link, true);
                }
                if (product.product_group) {
                    let tempProductGroup = { id: product.product_group.id, name: product.product_group.name }
                    await formik.setFieldValue("product_group", tempProductGroup, true);
                }
                // to check
            }
            getProductDetailedInfo(product_id)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    setDataInFormEdit(data)
                })


        }
        else if (similarProduct) {
            formik.setFieldValue("name", similarProduct.name + " -- [KOPIA]", true);
            let name = similarProduct.category.main_category.name + ' > ' + similarProduct.category.name
            let tempCategory = { id: similarProduct.category.id, name: name }
            formik.setFieldValue("category", tempCategory, true);
            formik.setFieldValue("product_group", similarProduct.product_group, true);
            formik.setFieldValue("price", similarProduct.price, true);
            formik.setFieldValue("quantity", similarProduct.quantity, true);
            formik.setFieldValue("waiting_time", similarProduct.waiting_time, true);
            formik.setFieldValue("short_description", similarProduct.short_description, true);
            formik.setFieldValue("description", similarProduct.description, true);
            formik.setFieldValue("parameters", similarProduct.parameters, true);
            formik.setFieldValue("note", similarProduct.note, true);
            // if (similarProduct.shop_link) {
            //     formik.setFieldValue("shop_link", similarProduct.shop_link, true);
            // }

        }
    }, [product_id])

    const options = ['bold', 'italic', '|', 'ul', 'ol', 'header1', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];
    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: '',
            defaultActionOnPaste: 'insert_as_html',
            defaultLineHeight: 1.5,
            enter: 'div',
            // options that we defined in above step.
            buttons: options,
            buttonsMD: options,
            buttonsSM: options,
            buttonsXS: options,
            statusbar: false,
            sizeLG: 900,
            sizeMD: 700,
            sizeSM: 400,
            toolbarAdaptive: false,
        }),
        [],
    );
    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    const headerMainImages = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const formatedValue = mainPhotoUploadRef && mainPhotoUploadRef.current ? mainPhotoUploadRef.current.formatSize(mainImagesTotalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue}</span>

                </div>
            </div>
        );
    };
    const headerOtherImages = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const formatedValue = otherPhotoUploadRef && otherPhotoUploadRef.current ? otherPhotoUploadRef.current.formatSize(otherImagesTotalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue}</span>

                </div>
            </div>
        );
    };

    const onMainPhotoSelect = (e) => {
        let _totalSize = mainImagesTotalSize;
        let files = e.files;
        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });
        formik.setFieldValue("main_images_files", files);
        setMainImagesTotalSize(_totalSize);
    };

    const onOtherPhotoSelect = (e) => {
        let _totalSize = otherImagesTotalSize;
        let files = e.files;
        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;

        });

        formik.setFieldValue("image_files", files);
        setOtherImagesTotalSize(_totalSize);


    };
    const onMainImagesClear = () => {
        setMainImagesTotalSize(0);
        formik.setFieldValue("main_images_files", []);
    };

    const onOtherImagesClear = () => {
        setOtherImagesTotalSize(0);
        formik.setFieldValue("image_files", []);
    };
    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Przeciągnij zdjęcia tutaj
                </span>
            </div>
        );
    };

    const handleRemoveImage = (image) => {
        const newArray = curentImages.filter((item, i) => item.id !== image.id);
        setcurentImages(newArray);

    }

    return (
        <>{isAdmin ?
            (
                <div className='crete-product-main'>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="name">Nazwa produktu</label>
                                {formik.errors.name && formik.touched.name && (
                                    <div className="error-message">{formik.errors.name}</div>
                                )}
                                <InputText
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="shop_link">Link do Allegro</label>
                                {formik.errors.shop_link && formik.touched.shop_link && (
                                    <div className="error-message">{formik.errors.shop_link}</div>
                                )}
                                <InputText
                                    id="shop_link"
                                    name="shop_link"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.shop_link}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="category">Kategoria</label>
                                {formik.errors.category && formik.touched.category && (
                                    <div className="error-message">{formik.errors.category}</div>
                                )}
                                <Dropdown
                                    id="category"
                                    options={categories}
                                    name="category"
                                    type="text"
                                    onChange={(e) =>
                                        formik.setFieldValue('category', e.value)
                                    }
                                    value={formik.values.category}
                                    placeholder="Wybierz kategorie"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="product_group">Grupa produktów</label>
                                {formik.errors.product_group && formik.touched.product_group && (
                                    <div className="error-message">{formik.errors.product_group}</div>
                                )}
                                <Dropdown
                                    id="product_group"
                                    options={productGroups}
                                    name="product_group"
                                    type="text"
                                    onChange={(e) =>
                                        formik.setFieldValue('product_group', e.value)
                                    }
                                    value={formik.values.product_group}
                                    placeholder="Wybierz grupe produktów"
                                    optionLabel="name"
                                    filter
                                    showClear
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="short_description">Krótki opis</label>
                                {formik.errors.short_description && formik.touched.short_description && (
                                    <div className="error-message">{formik.errors.short_description}</div>
                                )}
                                <JoditEditor
                                    ref={editorRef}
                                    value={formik.values.short_description}
                                    config={config}
                                    onChange={(htmlString) => formik.setFieldValue("short_description", htmlString)}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="description">Opis</label>
                                {formik.errors.description && formik.touched.description && (
                                    <div className="error-message">{formik.errors.description}</div>
                                )}
                                <JoditEditor
                                    ref={editorRef}
                                    value={formik.values.description}
                                    config={config}
                                    onChange={(htmlString) => formik.setFieldValue("description", htmlString)}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="parameters">Parametry</label>
                                {formik.errors.parameters && formik.touched.parameters && (
                                    <div className="error-message">{formik.errors.parameters}</div>
                                )}
                                <JoditEditor
                                    ref={editorRef}
                                    value={formik.values.parameters}
                                    config={config}
                                    onChange={(htmlString) => formik.setFieldValue("parameters", htmlString)}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="note">Uwagi</label>
                                {formik.errors.note && formik.touched.note && (
                                    <div className="error-message">{formik.errors.note}</div>
                                )}
                                <JoditEditor
                                    ref={editorRef}
                                    value={formik.values.note}
                                    config={config}
                                    onChange={(htmlString) => formik.setFieldValue("note", htmlString)}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="price">Cena</label>
                                {formik.errors.price && formik.touched.price && (
                                    <div className="error-message">{formik.errors.price}</div>
                                )}
                                <InputNumber
                                    inputId="stacked-button"
                                    id="price"
                                    name="price"
                                    showButtons
                                    mode="currency"
                                    currency="PLN"
                                    step={1}
                                    onValueChange={(e) => formik.setFieldValue('price', e.value)}

                                    value={formik.values.price}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="quantity">Ilość sztuk</label>
                                {formik.errors.quantity && formik.touched.quantity && (
                                    <div className="error-message">{formik.errors.quantity}</div>
                                )}
                                <InputNumber
                                    inputId="stacked-button"
                                    id="quantity"
                                    name="quantity"
                                    showButtons
                                    mode="decimal"
                                    min={0}
                                    max={100}
                                    step={1}
                                    onValueChange={(e) => formik.setFieldValue('quantity', e.value)}

                                    value={formik.values.quantity}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="waiting_time">Czas oczekiwania</label>
                                {formik.errors.waiting_time && formik.touched.waiting_time && (
                                    <div className="error-message">{formik.errors.waiting_time}</div>
                                )}
                                <InputNumber
                                    inputId="stacked-button"
                                    id="waiting_time"
                                    name="waiting_time"
                                    showButtons
                                    mode="decimal"
                                    suffix=" dni"
                                    min={0}
                                    max={100}
                                    step={1}
                                    onValueChange={(e) => formik.setFieldValue('waiting_time', e.value)}

                                    value={formik.values.waiting_time}
                                />
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="main_photo">Zdjecia głowne</label>
                                {formik.errors.main_images_files && formik.touched.main_images_files && (
                                    <div className="error-message">{formik.errors.main_images_files}</div>
                                )}
                                <div>
                                    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                                    <FileUpload ref={mainPhotoUploadRef} name="main_images" multiple accept="image/*" maxFileSize={10000000}
                                        onSelect={onMainPhotoSelect} onError={onMainImagesClear} onClear={onMainImagesClear}
                                        headerTemplate={headerMainImages} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions} uploadOptions={cancelOptions} cancelOptions={cancelOptions} />
                                </div>
                                {/* <FileUpload name="main_pictures" url={'/api/upload'} customUpload uploadHandler={customBase64Uploader} multiple accept="image/*" maxFileSize={10000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} /> */}
                            </div>
                            <div className="flex flex-column gap-2 form-field">
                                <label htmlFor="photo">Pozostałe zdjecia</label>
                                {formik.errors.other_images && formik.touched.other_images && (
                                    <div className="error-message">{formik.errors.other_images}</div>
                                )}
                                <div>
                                    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                                    <FileUpload ref={otherPhotoUploadRef} name="other_images" multiple accept="image/*" maxFileSize={10000000}
                                        onSelect={onOtherPhotoSelect} onError={onOtherImagesClear} onClear={onOtherImagesClear}
                                        headerTemplate={headerOtherImages} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions} uploadOptions={cancelOptions} cancelOptions={cancelOptions} />
                                </div>
                                {/* <FileUpload name="main_pictures" url={'/api/upload'} customUpload uploadHandler={customBase64Uploader} multiple accept="image/*" maxFileSize={10000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} /> */}
                            </div>
                            {curentImages.length ? <ProductPhotosEdit images={curentImages} removeImage={handleRemoveImage} /> : null}

                            <button type="submit">Zapisz</button>
                        </form >
                    </FormikProvider>
                </div >
            ) : (<NoAccessPage />)
        }
        </>
    )
};

export default ProductCreateComponent;
