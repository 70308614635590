import React, { useState, useEffect, useRef } from "react";
import SimilarProductCard from './SimilarProductCard';
import '../style/SimilarProductSlider.css'

const SimilarProductSlider = ({ productGroup, activeProductId, mainCategoryId }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleItems, setVisibleItems] = useState(1);
    const sliderRef = useRef(null);
    const [similarProducts, setSimilarProducts] = useState([]);

    // Zmienne do obsługi gestów
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);


    useEffect(() => {
        const API_URL = process.env.REACT_APP_API_URL;
        if (activeProductId) {
            let link = `${API_URL}/api/product-list/`;
            let paginationParams = `page_size=10`;
            let filtersString = `?main_category=${mainCategoryId}`;
            if (productGroup) {
                filtersString += `&product_group_not_in=${productGroup.id}`;
            }

            link = `${link}${filtersString}&${paginationParams}`
            fetch(link)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    setSimilarProducts(data.results);

                });

        }


    }, [activeProductId, productGroup, mainCategoryId]);

    const handleSwipe = () => {
        const distance = touchStartX.current - touchEndX.current;
        if (distance > 50) {
            // Swipe w lewo
            moveNext();
        } else if (distance < -50) {
            // Swipe w prawo
            movePrev();
        }
    };

    const moveNext = () => {
        if (currentIndex < similarProducts.length - visibleItems) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    // Przejście do poprzedniego elementu
    const movePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };


    useEffect(() => {
        const updateVisibleItems = () => {
            const width = window.innerWidth;
            if (width >= 1200) setVisibleItems(6); // 5 elementów na szerokim ekranie
            else if (width >= 992) setVisibleItems(4); // 4 elementy na średnim ekranie
            else if (width >= 768) setVisibleItems(3); // 3 elementy na tabletach
            else if (width >= 576) setVisibleItems(2); // 2 elementy na małych ekranach
            else setVisibleItems(1); // 1 element na bardzo małych ekranach
        };

        updateVisibleItems();
        window.addEventListener("resize", updateVisibleItems);


        return () => window.removeEventListener("resize", updateVisibleItems);

    }, []);


    const nextSlide = () => {
        if (currentIndex < similarProducts.length - visibleItems) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const productList = similarProducts.map((product) => <SimilarProductCard product={product} key={product.id} />);
    return (
        productList.length > 0 ? (
            <div className='product_proposal'>
                <h1>Podobne produkty</h1>
                <div className="slider-container">
                    {/* Przyciski nawigacyjne */}
                    <button className="slider-button prev" onClick={prevSlide} disabled={currentIndex === 0}>
                        &#10094;
                    </button>
                    <div className="slider-wrapper"
                        ref={sliderRef}
                        onTouchStart={(e) => (touchStartX.current = e.touches[0].clientX)}
                        onTouchMove={(e) => (touchEndX.current = e.touches[0].clientX)}
                        onTouchEnd={handleSwipe}>
                        <div
                            className="slider-content"
                            style={{
                                transform: `translateX(-${(currentIndex * 100) / productList.length}%)`,
                                width: productList.length < visibleItems ? '100%' : `${(productList.length * 100) / visibleItems}%`,
                            }}
                        >
                            {productList.map((item, index) => (
                                <div
                                    className="slider-item"
                                    key={index}
                                    style={{ width: `${100 / visibleItems}%` }}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        className="slider-button next"
                        onClick={nextSlide}
                        disabled={currentIndex >= productList.length - visibleItems}
                    >
                        &#10095;
                    </button>
                </div>
            </div >) : null

    );
};

export default SimilarProductSlider;
