import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/ShopProductCard.css';
import { increaseProductQuantity, increasePriority } from '../service/ProductService'

const ShopProductCard = (props) => {
    let navigate = useNavigate();
    const product_url = `/sklep/produkt/${props.product.product_id}`;

    const [mainPagePriority, setMainPagePriority] = useState(props.product.main_page_priority);
    const [quantity, setQuantity] = useState(props.product.quantity);

    function onLinkClick(e) {
        e.preventDefault();
        navigate(product_url);
    }

    const mainImg = props.product.images.find((element) => element.main == true);
    const isAdmin = props.isAdmin;

    const handlePriorityChange = (change) => {
        if (change === -1 && mainPagePriority === 0) {
            return;
        }
        increasePriority(props.product.id, change)
            .then(response => {
                if (response.status === 400) {
                    throw new Error('Invalid increase value');
                }
                return response.json();
            })
            .then(data => setMainPagePriority(data.main_page_priority))
            .catch(error => console.error('Error:', error));
    };

    const handleCountChange = (change) => {
        if (change === -1 && quantity === 0) {
            return;
        }
        increaseProductQuantity(props.product.id, change)
            .then(response => {
                if (response.status === 400) {
                    throw new Error('Invalid increase value');
                }
                return response.json();
            })
            .then(data => setQuantity(data.quantity))
            .catch(error => console.error('Error:', error));
    };

    return (
        <div className="col-12 md:col-6 lg:col-4 xl:col-4 p-2" key={props.product.id}>
            <div className="product-card">
                {/* Kategoria produktu */}

                <div className="product-card-category"><i className="pi pi-tag"></i> {props.product.main_category} / {props.product.category}</div>

                {/* Zdjęcie produktu */}

                <div className="product-card-image-container">
                    <a href={product_url} onClick={onLinkClick}>
                        <img src={mainImg.image} alt={props.product.name} className="product-card-image" />
                    </a>
                </div>

                {/* Nazwa produktu */}
                <div className="product-card-name">
                    <a href={product_url} onClick={onLinkClick}>
                        <h3>{props.product.name}</h3>
                    </a>
                </div>

                {/* Cena i przycisk */}
                <div className="product-card-footer">
                    <span className="product-card-price">{props.product.price} zł</span>
                    <a href={product_url} onClick={onLinkClick} target="_blank" className="product-card-button product-card-allegro">Kup teraz</a>

                </div>
                {isAdmin && (
                    <div className="product-card-edit-options">
                        <div className="edit-option">
                            <span>Priorytet na stronie:</span>
                            <div className="edit-buttons">
                                <button onClick={() => handlePriorityChange(-1)}>-</button>
                                <span>{mainPagePriority}</span>
                                <button onClick={() => handlePriorityChange(1)}>+</button>
                            </div>
                        </div>
                        <div className="edit-option">
                            <span>Ilość:</span>
                            <div className="edit-buttons">
                                <button onClick={() => handleCountChange(-1)}>-</button>
                                <span>{quantity}</span>
                                <button onClick={() => handleCountChange(1)}>+</button>
                            </div>
                        </div>
                        <div className="edit-option">
                            <span>Wyświetlenia:</span>
                            <div className="edit-buttons">
                                <span>{props.product.visits}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>


        </div >
    )
};

export default ShopProductCard;

{/* <div className='shop-product-card'>
<div className='shop-item-card-header'>
    <i className="pi pi-tag"></i>
    <span className="font-semibold">{props.product.main_category} / {props.product.category}</span>
</div>

<div className='shop-item-card-main'>
    <a href={product_url} onClick={onLinkClick}>
        {main_img ?
            (<img src={main_img.image} alt={props.product.name} onClick={() => navigate(`/sklep/produkt/${props.product.product_id}`)} />) : null

        }
    </a>
    <div className='shop-item-card-info'>
        <a href={product_url} onClick={onLinkClick}>
            <div className="shop-item-card-name" >{props.product.name}</div>
        </a>
        <div className='shop-product-price'>

            <h3>{props.product.price} PLN</h3>
            {/* <Button icon="pi pi-shopping-cart" className="p-button-rounded" disabled={product.quantity <= 0}></Button> */}
// { props.product.shop_link ? <a href={props.product.shop_link} target="_blank" className="button allegro">Kup teraz</a> : null }

//         </div >
//     </div >
// </div >



// </div > */}