import React from "react"
import { Route, Routes } from "react-router-dom";
import Shop from "./Shop";
import AboutUs from "./AboutUs";
import Projects from "./Projects";
import Contact from "./Contact";
import ErrorPage from "./ErrorPage";
import ProductPageComponent from "./ProductPageComponent";
import ProductCreateComponent from "./ProductCreateComponent";
import MyAccount from "./MyAccount";



const MainPage = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" exac Component={Shop} />
        <Route path="/sklep" Component={Shop} />
        <Route path="/sklep/produkt/:product_id" Component={ProductPageComponent} />
        <Route path="/admin/dodaj_produkt" Component={ProductCreateComponent} />
        <Route path="/admin/edytuj_produkt/:product_id" Component={ProductCreateComponent} />
        <Route path="/sklep/kategoria/:main_category_id" Component={Shop} />
        <Route path="/sklep/kategoria/:main_category_id/:sub_category_id" Component={Shop} />
        <Route path="/kontakt" Component={Contact} />
        <Route path="/o-nas" Component={AboutUs} />
        <Route path="/projekty" Component={Projects} />
        <Route path="/kontakt" Component={Contact} />
        <Route path="/moje-konto" Component={MyAccount} />
        <Route path="*" Component={ErrorPage} />

      </Routes>
    </>
  )
};

export default MainPage;
