import React from "react"
import '../style/Footer.css'

const Footer = (props) => {
  const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL;
  const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL;

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-contact">
          <h1 className="footer-contact-item">
            <strong>Zapraszamy do kontaktu:</strong>
          </h1>
          {/* <p className="contact-item">
          <i className="pi pi-phone" style={{ fontSize: '1rem' }}></i>
          <a href="tel:+48123456789">+48 123 456 789</a>
        </p> */}
          <p className="footer-contact-item">
            <i className="pi pi-envelope" style={{ fontSize: '1rem' }}></i>
            <a href="mailto:example@email.com">zetkagraw@gmail.com</a>
          </p>
          <div className="footer-social-links">
            <a
              href={FACEBOOK_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
              aria-label="Facebook"
            >
              <i className="pi pi-facebook" style={{ fontSize: '2rem' }}></i>
            </a>
            <a
              href={INSTAGRAM_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
              aria-label="Instagram"
            >
              <i className="pi pi-instagram" style={{ fontSize: '2rem' }}></i> zetka_graw
            </a>
          </div>
        </div>

        {/* Copyright w prawym dolnym rogu */}
        <div className="footer-copyright">
          <p>© {new Date().getFullYear()} ZETKA-GRAW. Wszelkie prawa zastrzeżone.</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
